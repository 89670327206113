import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const Price = ({setStep}) => {

  useEffect(() => {
   setStep(4);
  }, [])
 
  return (
    <>
      
    </>
  );
};

export default Price;
